.App {
  display: flex;
  text-align: center;
  flex-direction: row;
}

.Dashboard {
  width:100%;
  display: flex;
  text-align: center;
  flex-direction: row;
}

.Settings {
  width:100%;
  display: flex;
  text-align: center;
  flex-direction: row;
}

.App-sidebar {
  width: 20%;
}

.App-table {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  font-size: small;
  text-align: center;
  height: 70vh;
}

.App-side-menu {
  width:20%;
  height: 97%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
  margin-right: 0.5%;
  background-color: #fff;
  border-radius: 5px;
  border: lightgrey solid 1px;
  color:black;
  font-size: medium;
}

.App-side-menu-title {
  margin: 10px;
  font-size: large;
  font-weight: bold;
}

.App-side-menu-body {
  height:80vh;
  overflow:scroll;
}

.navsidebar-menu {
  height:550px;
  overflow:scroll;
}

.App-box-small {
  width:80%;
  height: 97%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  margin-left: 0.5%;
  background-color: #fff;
  border-radius: 5px;
  border: lightgrey solid 1px;
  color:black;
}

.App-box-small-empty {
  width:80%;
  height: 97%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  margin-left: 0.5%;
  background-color: #eee;
}

.App-list-group {
  width: 40%;
  margin: 10px;
  font-size: small;
}

.App-list-group-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.App-sub-title {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-weight: normal;
  padding-left: 50px;
  padding-top: 20px;
  color: black;
  font-size: medium;
}

.App-list-group-item-1 {
  text-decoration: underline;
}

.App-list-group-item-2 {
  margin-left: 10px;
  font-weight: bold;
}

.App-list-group-item-2-row {
  margin-left: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.App-box-upper {
  display: flex;
  flex-direction: row;
}

.App-map {
  width: 60%;
  margin: 10px;
}

.table-responsive{
  overflow:scroll;
  max-height: 75vh;
}

thead tr:nth-child(1) th{
  background: #EAEAEA;
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
}

td {
  text-align: center;
  vertical-align: middle;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    margin-top:30px;
    height: 10px;
    margin-bottom:30px;
  }
}

.App-header {
  width:80%;
  background-color: #eee;
  border-left: lightgrey solid 1px;
  min-height: calc(100vh - 60px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
}

.App-box {
  width:98%;
  height: 97%;
  margin:1%;
  background-color: #fff;
  border-radius: 5px;
  border: lightgrey solid 1px;
}

.basic-navbar {
  border-bottom: lightgrey solid 1px;
  box-shadow: rgba(220,220,220,0.8) 0px 0px 3px 3px;
  display: block;
  height:60px;
}

.basic-nav-dropdown {
  justify-content: center;
  align-items: center;
}

.scroll-nav-dropdown {
  max-height: 400px;
  display: block;
  overflow-y: auto;
}

.basic-nav-dropdown-header {
  transform: translateY(-15px);
}

.navbar-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-left: 3px;
}

.navbar-button-text {
  margin-left: 5px;
}

.app-title {
  font-size: xx-large;
}

.navsidebar-title {
  font-size: x-large;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: solid lightgrey 1px;
  border-bottom: solid lightgrey 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left:30px;
  box-shadow: rgba(220,220,220,0.8) 0px 0px 4px 4px;
}

.navsidebar-title-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.navsidebar-title-text {
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  padding-left:30px;
}

.navsidebar-title-add {
  display: flex;
  align-items: center;
  border: transparent;
  background: transparent;
  margin-right: 10px;
}

.loading-square {
  box-sizing: border-box;
  height:100px;
}

@media all and (min-width: 480px) {
  .login {
    width:100%;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .register {
    width:100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .register-text {
    margin-bottom: 20px;
  }

  .login-box {
    width:40%;
    padding: 30px;
    border: lightgrey solid 1px;
    border-radius: 40px;
    box-shadow: rgba(220,220,220,0.8) 0px 0px 6px 6px;
  }

  .login-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .login-title {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: x-large;
  }

  .login-button {
    border: darkslategrey solid 1px;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    background: white;
  }

  .logout-button {
    border: lightgrey solid 1px;
    border-radius: 5px;
    background: white;
    color: black;
  }

  .login form {
    margin: 0 auto;
    max-width:260px;
  }

  .register form {
    margin: 0 auto;
    max-width:260px;
  }
}

.table-button {
  background-color: transparent;
  border: transparent;
}

.App-title {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
  display: flex;
  justify-content: flex-start;
  padding-top: 50px;
  padding-left: 50px;
}

.App-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  font-size: large;
}

.Settings-button {
  width: 90%;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  font-size: large;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Settings-input {
  width: 90%;
  color:black;
  display:flex;
  align-items: flex-start;
  flex-direction:column;
  padding-left: 50px;
  margin-top: 20px;
}

.Subscription-cost-input {
  width: 45%;
  color:black;
  display:flex;
  align-items: flex-start;
  flex-direction:column;
  padding-left: 20px;
  margin-top: 20px;
}

.login-forgot-passowrd {
  margin-top: 30px;
}

.confirm-email {
  display: flex;
  justify-content: center;
  padding: 30px;
  font-size: large;
}

.modal-container {
  display:flex;
  flex-direction:row;
}

.modal-item
  .left {
    align-self: center;
    text-align: left;
    overflow: hidden;
    width: 30%;
  }

  .right {
    align-self: center;
    text-align: right;
    overflow: hidden;
    margin-right: 20px;
    width: 40%;
  }

.switch-button-item {
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
}

.device-data-button {
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  cursor:pointer;
}

.device-data-button-row {
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  cursor:pointer;
}